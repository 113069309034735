<template>
  <div>{{ type }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            typeRecherches: 'sanitaire/typeRecherches' 
        }),
        type(){
            let tr = this.typeRecherches.find(item => item.uid === this.object.typeRecherche)
            if(!tr) return '-'
            return tr.libelle
        }
    }
}
</script>

<style>

</style>